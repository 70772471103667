import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import { authSlice } from './auth/auth.slice'
import { appSlice } from './app/app.slice'
import { calendarSlice } from './calendar/calendar.slice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    app: appSlice.reducer,
    calendar: calendarSlice.reducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
