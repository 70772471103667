import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CalendarMode } from '@modules/project-planning/components/Planning/components/Calendar/Calendar.types'

// eslint-disable-next-line max-len
import { DEFAULT_WIDTH_FOR_DAY } from '@modules/project-planning/components/Planning/components/Calendar/Calendar.constants'

type Pointer = {
  dayIndex: number
  startX: number
  rowId: string
  stickyRowIndex1: number
  stickyRowIndex2: number
  stickyRowIndex3: number
}

type Period = {
  startDate: string
  duration: number
}

type Task = {
  id: string
  zoneId: string
}

export interface CalendarState {
  mode: CalendarMode
  dayWidth: number
  pointer?: Pointer
  selectedZoneId?: string
  selectedTasks: Task[]
  selectedPeriod?: Period
  resizingTask?: {
    id: string
    period: Period
  }
  isDragging: boolean
}

const getInitialState = (): CalendarState => ({
  mode: CalendarMode.Day21,
  dayWidth: DEFAULT_WIDTH_FOR_DAY,
  pointer: undefined,
  selectedZoneId: undefined,
  selectedTasks: [],
  resizingTask: undefined,
  isDragging: false,
})

export type SetModeType = CalendarMode

export type SetDayWidthType = number

export type SetPointerType = Pointer

export type SetSelectedZoneIdType = string | undefined
export type SetSelectedTaskType = Task
export type SetSelectedTasksType = Task[]

export type SetSelectedPeriodType = Period

export type SetResizingTaskType = {
  id: string
  period: Period
}

export type SetIsDraggingType = boolean

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: getInitialState(),
  reducers: {
    setMode: (state, action: PayloadAction<SetModeType>) => {
      state.mode = action.payload
    },
    setDayWidth: (state, action: PayloadAction<SetDayWidthType>) => {
      state.dayWidth = action.payload
    },
    setPointer: (state, action: PayloadAction<SetPointerType | undefined>) => {
      if (state.pointer?.rowId != action.payload?.rowId || state.pointer?.startX != action.payload?.startX) {
        state.pointer = action.payload
      } else if (!action.payload) {
        state.pointer = undefined
      }
    },
    setSelectedZoneId: (state, action: PayloadAction<SetSelectedZoneIdType>) => {
      state.selectedZoneId = state.selectedZoneId == action.payload ? undefined : action.payload
      state.selectedTasks = []
    },
    setSelectedTask: (state, action: PayloadAction<SetSelectedTaskType>) => {
      const task = action.payload
      const { selectedTasks } = state

      const filteredTasks = selectedTasks.filter((t) => t.id != task.id)

      if (selectedTasks[0] && selectedTasks[0].zoneId != task.zoneId) {
        state.selectedTasks = [task]
      } else if (filteredTasks.length == selectedTasks.length) {
        state.selectedTasks = [...selectedTasks, task]
      } else {
        state.selectedTasks = filteredTasks
      }

      state.selectedZoneId = undefined
    },
    setSelectedTasks: (state, action: PayloadAction<SetSelectedTasksType>) => {
      const currentTasksSelection = state.selectedTasks.map(({ id }) => id).join(',')
      const nextTasksSelection = action.payload.map(({ id }) => id).join(',')

      if (currentTasksSelection == nextTasksSelection) return

      state.selectedTasks = action.payload
      state.selectedZoneId = undefined
    },
    setSelectedPeriod: (state, action: PayloadAction<SetSelectedPeriodType | undefined>) => {
      if (
        state.selectedPeriod?.duration == action.payload?.duration &&
        state.selectedPeriod?.startDate == action.payload?.startDate
      ) {
        return
      }

      state.selectedPeriod = action.payload
    },
    setResizingTask: (state, action: PayloadAction<SetResizingTaskType | undefined>) => {
      state.resizingTask = action.payload
    },
    setIsDragging: (state, action: PayloadAction<SetIsDraggingType>) => {
      state.isDragging = action.payload
    },
  },
})
