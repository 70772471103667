export const DEFAULT_MARGIN_IN_DAY = 7 * 4 * 12 * 2

export const DEFAULT_NUMBER_OF_ROW_PER_ZONE = 1

export const DEFAULT_ZONE_WIDTH = 202
export const DEFAULT_TOP_PADDING = 65
export const DEFAULT_HEIGHT_FOR_DAY = 36
export const DEFAULT_WIDTH_FOR_DAY = 50
export const DEFAULT_GAP_FOR_DAY = 1

export const DEFAULT_HEIGHT_FOR_TASK = 28
export const DEFAULT_BORDER_WIDTH_FOR_TASK = 1.5
