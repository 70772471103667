import { TaskStatus, Zone } from '@types'

export type DayType = {
  key: string
  date: moment.Moment
  dayLetter: string
  dayNumber: string
  weekDay: number
  className: `weekDay${number}` | `weekDay${number} today`
}

export type MonthType = {
  key: string
  date: moment.Moment
  label: string
  countOfDay: number
}

export type WeekType = {
  key: string
  date: moment.Moment
  label: string
}

export type EnhancedZone = Zone & {
  className: 'building' | 'floor' | 'zone'
  countOfRow: number
  countOfDay: number
  countOfTask: {
    [key in TaskStatus]: number
  }
}

export enum CalendarMode {
  Day7 = 'day_7',
  Day14 = 'day_14',
  Day21 = 'day_21',
  Week4 = 'week_4',
  Week6 = 'week_6',
  Week8 = 'week_8',
  // Month3 = 'month_3',
  // Month6 = 'month_6',
  // Month12 = 'month_12',
}
