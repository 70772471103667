import { DefaultTheme, createGlobalStyle } from 'styled-components'

const StyledTheme: DefaultTheme = {
  fontFamily: "'SF Pro', sans-serif",

  borderRadius: '5px',

  colors: {
    primary: '#5A58F2',
    secondary: 'white',
  },

  responsive: {
    tablet: {
      valueString: '768px',
      valueNumber: 768,
    },
    mobile: {
      valueString: '480px',
      valueNumber: 480,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};

    user-select: none;
  }

  /* Custom Scrollbar Styles */
  * {
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 4px;
      margin: 8px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E5E7EB;
      border-radius: 4px;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #D1D5DB;
      }
    }

    /* Firefox Scrollbar Styles */
    scrollbar-width: thin;
    scrollbar-color: #E5E7EB transparent;

    &:hover {
      scrollbar-color: #D1D5DB transparent;
    }
  }

  .crisp-client {
    a {
      &.cc-1m2mf {
        @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
          margin-bottom: 55px !important;
        }
      }
    }
  }
`

export { StyledTheme, GlobalStyle }
